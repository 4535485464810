.blurb {
  @apply block relative;

  & h2 {
    @apply text-1.5xl sm:text-2xl lg:text-2.5xl;
  }

  & h2,
  & h3,
  & h4 {
    @apply text-has-hover-effect;
  }

  &:hover {
    & h2,
    & h3,
    & h4 {
      @apply text-hover-effect;
    }

    & figure img {
      @apply image-hover-effect;
    }
  }

  & :global(div[data-block="core/heading"]),
  & :global(div[data-block="core/paragraph"]) {
    & a {
      @apply relative z-20;
    }
  }

  & :global(div[data-block="core/paragraph"]) {
    & a {
      @apply underline;
    }
  }

  &:global(.hide-button div[data-block="core/paragraph"]) {
    @apply pb-0;
  }

  & :global(.media-wrapper) {
    @apply w-full mb-5;

    &:global(.loading) {
      @apply bg-black-100/10;
    }

    & figure {
      @apply relative aspect-w-16 aspect-h-12;
    }

    &:global(.fourth) {
      & figure {
        @apply aspect-w-1 aspect-h-1;
      }
    }

    & img {
      @apply image-has-hover-effect object-cover object-center w-full h-full;
    }
  }
}
